/* ContractPreview CSS */

.preview-box {
  padding: 24px 16px;
  gap: 24px;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

.sidebar-actions {
  display: flex;
  flex-direction: column;
  min-width: 310px;
  gap: 24px;
}

.sidebar-actions .btn-pay {
  border-radius: 5px;
  width: 90%;
}

.sign-box {
  background: #F5F5F5;
  border-radius: 8px;
}

.sidebar-tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  height: 47px;
  width: 100%;
}

.sidebar-tabs button {
  padding: 16px;
  height: 47px;
  width: 50%;
  font-size: 13px;
  font-weight: 700;
  color: #8C8C8C;
  background: #FFFFFF;
  border-width: 0px 0px 1px 0px;
  border-style: solid;
  border-color: #C5C5C5;
}

.sidebar-tabs .active {
  color: #155892;
  border-width: 1px 1px 0px 1px;
  border-radius: 2px 2px 0px 0px;
}

.documents-pannel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 18px 16px;
  gap: 16px;

  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: #C5C5C5;
  border-radius: 0px 0px 2px 2px;
}

.documents-pannel div button {
  text-decoration: none !important;
}

.documents-pannel div button:hover {
  color: #2075BE !important;
}

.modal-contract-ok {
  max-width:641px;
}

.modal-contract-edit,
.modal-contract-remove {
  max-width: 893px;
}

.alert-outline-success, .alert-outline-danger {
  background: #F6FFED;
  border-radius: 10px;
}

.alert-outline-success {
  border: 1px solid #B7EB8F;
}

.alert-outline-danger {
  border: 1px solid #EF9F9F;
}

.modal-contract-edit .fa-exclamation-circle,
.modal-contract-remove .fa-exclamation-circle {
  color: #F5650B !important;
  font-size: 21px;
}

.delete-sign-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  padding: 0px;
  gap: 8px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
  color: #E61F37;
}

.thin-border {
  border: 1px solid #D9D9D9;
  border-radius: 8px;
}

.modal-sign {
  max-width: 800px;
}

.qr-width {
  width: 15vw !important;
  height: auto;
  min-height: 175px;
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
   }
}

.qr-loading{
  width: auto;
  height: 268px;
}

@media print {
  #qr-code {
    width: 500px;
    height: auto;
  }
}

.waiting-alert {
  background-color: #FFF7E8;
  border-color: #FFE6BA;
  text-align: start;
}

.payment-resume-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;
  border: 1px solid #BFBFBF;
  border-radius: 8px;
  width: 466px;
  height: 188px;

}

.repertory-modal, .save-form-modal {
  max-width:641px;
}

.repertory-modal .modal-content .modal-header {
  background-color: #0B3254;
  color: #FFFFFF;
}

.repertory-modal .modal-content .modal-body .form-group label {
  color: #0B3254;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  margin-bottom: 20px;
}

.medium-gap {
  row-gap: 2rem;
}

.h1-title {
  font-size: 1.7rem;
  font-weight:900;
}

.repertory {
  font-size: 1.6rem;
}

.bolder {
  font-weight: bolder !important;
}