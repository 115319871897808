@import url(https://fonts.googleapis.com/css?family=Roboto:100,400,500,700);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,600);
@import url(https://s3.amazonaws.com/static.autofactpro.com/styles-qa/app.css);
html,
body,
#root {
  height: 100%;
  margin: 0;
}

.field-row {
  display: flex;
  justify-content: space-between;
}

.field-row-preference {
  display: flex;
}

.botton-row {
  padding-top: 30px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.loading-overlay {
  z-index: 999;
  position: absolute;
  background: rgba(0, 0, 0, 0.562);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.loading {
  position: relative;
  top: 30%;
}

.main-container {
  padding: 20px 45px;
  min-height: 400px;
  background: #f2f2f2;
}

.nav-item a {
  cursor: pointer;
}

.navbar-brand {
  display: flex;
  align-items: center;
}

.navbar-brand > img {
  width: 110px;
}

.icon-title {
  height: 25.5px;
  margin-right: 17px;
  font-size: 21px;
  color: #0cb7ef;
}

.btn-see-link {
  font-size: 12px;
  font-weight: bold;
  width: 44px;
  height: 25px;
  display: inline-block;
  text-align: center;
  padding-top: 4px;
  border-radius: 10%;
  border: none;
}

.btn-see-link:hover {
  text-decoration: none;
}

.main-wrapper-page {
  background: #fff;
  padding: 20px 40px;
  border-radius: 5px;
}
.main-wrapper-page .field-row,
.pre-admission-detail-page dl.row {
  padding: 20px 0;
  border-bottom: 1px dashed #e7eaec;
}

.main-wrapper-page .field-row-preference,
.pre-admission-detail-page dl.row {
  padding: 20px 0;
  border-bottom: 1px dashed #e7eaec;
}

.pre-admission-detail-page dt {
  text-align: right;
}

.pre-admission-detail-page dd {
  border: 1px solid #e5e6e7;
  border-radius: 1px;
  padding: 6px 12px;
  margin: 0;
  height: 35px;
}

.pre-admission-detail-page dd.no-border {
  border: none;
}

.pre-admission-form .form-group.row {
  margin-bottom: 0;
}

.pre-admission-form label {
  font-weight: bold;
}

.indicator {
  background-color: #55ccf4;
  color: #fff;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  text-align: center;
}

.indicator > .indicator-number {
  font-size: 12px;
  font-weight: bold;
  position: relative;
}

.massive-checkbox {
  margin-left: 10px;
}

.massive-view > .field-headers {
  font-size: 14px;
  color: #1e3c5a;
  border-bottom: 2px solid #d5dae0;
}

.field-headers > div > .massive-checkbox {
  margin-left: 25px;
}

.massive-view > .background {
  background-color: rgba(0, 0, 0, 0.03);
}

.massive-view > .data-row:hover {
  background-color: #f2f2f2;
}

.massive-view > .background-selected {
  background-color: #f2f2f2;
}

.notary-form select, .notary-form input {
  color: #93a3af;
}

.donwload-button {
  color: green !important;
}

.nav-tabs.tabs-light {
  background: #F2F2F2;
}

.nav-tabs.tabs-light .nav-item {
  color: #0CB7EF;
  font-weight: 500;
}
.nav-tabs.tabs-light .nav-link.active, .nav-tabs.tabs-light .nav-link:hover {
  background: #FFFFFF;
  border: none;
  color: #FF562E
}
.request-up-form label {
  color: #93a3af;
}
.request-up-form button {
  border: none;
}

.request-up-form .custom-file-label {
  height: 30px;
  padding: .375rem .75rem;
}
.request-up-form .custom-file-label:after {
  content:'Examinar' !important;
  margin-right: 5px;
  margin-top: 5px;
  height: 20px;
  background-color: #B1B1B1;
  color: #FFFFFF;
  font-size: 12px;
  padding: 0px 10px;
}

.sumary .no-request-up-box{
  border-left: #FF562E 8px solid;
}
.sumary .no-request-up-title{
  color: #FF562E;
}
.sumary .wait-aprove-box{
  border-left: #0CB7EF 8px solid;
}
.sumary .wait-aprove-title{
  color: #0CB7EF;
}
.sumary .signed-box{
  border-left: #13AD2B 8px solid;
}
.sumary .signed-title{
  color: #13AD2B;
}
.sumary .wait-repertory-box{
  border-left: #FFA401 8px solid;
}
.sumary .wait-repertory-title{
  color: #FFA401;
}
.sumary .wait-sign-box{
  border-left: #155892 8px solid;
}
.sumary .wait-sign-title{
  color: #155892;
}
.sumary .wait-receive-box{
  border-left: #8B4513 8px solid;
}
.sumary .wait-receive-title{
  color: #8B4513;
}
.sumary .contract-to-enter-box{
  border-left: #FFB6C1 8px solid;
}
.sumary .contract-to-enter{
  color: #000000;
}
.sumary .summary-box{
  background-color: #F5F6F7;
  box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.4);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 12px;
}
.sumary .sumary-title {
  font-size: 41px;
  margin-top: 12px;
  font-family: Roboto;
  font-weight: 100;
}
.sumary .sumary-description{
  color:#555555;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 400;
}
.sumary .show-more{
  color:#155892;
  font-size: 12px;
  font-family: Roboto;
  font-weight: 400;
  padding: 5px;
  background-color: #FFFFFF;
  margin-top: auto;
  cursor: pointer;
  width: 65px;
}
.none {
  display:none !important;
}
.general-summary-title{
  color: #555555;
  font-size: 20px;
  font-family: Raleway;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
}
.general-summary-title strong{
  color: #000000;
  font-size: 20px;
  font-weight: bold;
}
.general-summary-title .icon {
  color:#C7C7C7
}
.no-show { display:none; }
.request-upload-list-title{
  font-size: 16px;
  font-family: Raleway;
  font-weight: 600;
}

.mark-as-entered {
  padding: 20px;
}

.not-show {
  display: none;
}

.remote-video video{
  width: 320px;
  height: 320px;
  visibility: visible!important;
}


.identity-meet-wrapper {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
}

.local-video {
  width: 100%;
  height: 100%;
  justify-content: center;
}

.local-video video {
  object-fit: contain;
  width: 320px;
  margin: 0 auto;
}

.remote-video {
  position: absolute;
  bottom: 10px;
  right: 30px;
  width: 40%;
  overflow: hidden;
}

.remote-video video {
  visibility: visible !important;
  object-fit: contain;
  width: 100%;
}

.request-upload-label {
  margin-left: 30px;
  size: 40px;
}

.separation-gap {
  margin-top:15px;
  margin-bottom:15px;
  height: 30px;
}


.tabs-button {
  font-weight: normal;
  margin-left: -15px;
}


.tabs-button .nav-item {
  color:#93a3af;
  width: 150px;
  text-align: center;
  background-color: #F2F2F2;;
}

.tabs-button .nav-item:hover {
  font-weight: bold;
}

.tabs-button .nav-link.active {
  color:white;
  background-color: #bababa;
  border-radius: 2px;
  font-weight: bold;
}

.tabs-button .nav-link.active:hover {
  color:white;
  background-color: #bababa;
  font-weight: bold;
}

.alert-fixed {
    position:fixed;
    left: 1%;
    width: 98%;
    z-index:9999;
}

.orange-color {
  color: #F5650B;
}

.yellow10-color {
  color: #FFAC1A;
}

.yellow-color {
  color: #FFA401;
}

.green-ok-color {
  color: #00875A;
}
.primary-dark-color {
  color: #155892;
}

.gray-light-bg {
  background-color: #F5F5F5;
}

.white-bg {
  background-color: #FFFFFF;
}

.yellow1-bg {
  background: #FFF7E8;
}

.yellow3-bg {
  background: #FFE6BA;
}

.yellow-number-icon {
  background: #FFA401;
  width: 24px;
  height: 24px;
  color: #FFFFFF;
}

.text-11 {
  font-size: 11px;
  line-height: 26px;
}

.text-14 {
  font-size: 14px;
  line-height: 22px;
}
.text-16 {
  font-size: 16px;
  line-height: 24px;
}

.text-18 {
  font-size: 18px;
}

.text-20 {
  font-size: 20px;
  line-height: 28px;
}

.text-26 {
  font-size: 26px;
  line-height: 30px;
}

.m-0 {
  margin: 0;
}

.tooltip-form-help .tooltip-inner {
  color: #FFFFFF;
  background-color: #0B3254;
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.bs-tooltip-right .arrow::before{
  border-right-color: #0B3254 !important;
}

.bs-tooltip-left .arrow::before {
  border-left-color: #0B3254 !important;
}

.underline-text-decoration {
  text-decoration: underline
}

.notary-transference-stepper {
  background: none !important;
}

.notary-transference-stepper .MuiStepIcon-root.MuiStepIcon-active {
  color: #0B3254;
}

.modal-header {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.tag{
  color: gray;
  font-weight: bolder !important;
}

.tag-revision {
  color: #ffac1a;
}

.tag-tax {
  color: #ff4e04;
}

.tag-sign {
  color: #701a82;
}

.tag-rc {
  color: #0ea7e2;
}

.tag .svg-inline--fa {
  color: currentColor;
}

.tag-transferred {
  color: #237804;
}

.tag-rejected, .exclamation-red {
  color: #DB2929;
}

.gray9-color {
  color: #262626
}

.tag-rejected, .error-color {
  color: #DB2929 !important;
}

.bigger-checkbox {
  width: 20px;
  height: 20px;
}

.full-height {
  height: 100%;
}

.grid-relative{
  position: relative;
}
.grid-relative .MuiPaper-root{
  height: 100% !important;
}

.card-grid-action-bar{
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: row;
  grid-gap: 5px;
  gap: 5px;
  width: 100%;
  margin-bottom: -25px;
  margin-left: -15px;
}

.card-grid-action-bar > button{
 font-size: smaller;
}

.extra-margin-bottom{
  margin: 0 0 30px 0 !important;
}

.transference-status-38 {
    background: #F24726;
}
.btn {
    font-size: medium;
}
.btn > i {
    font-weight: bolder ;
    margin-right: .5em;
}
.font-xl{
    font-size: x-large;
}

.font-sm{
    font-size: 1.1em;
}

.rt-thead .rt-th {
    color: #155892 !important;
}

.btn-sm {
    text-decoration: none;
}

.tabs-container .tab-pane{
    padding: 20px 0 0 0;
}

.nav-tabs .nav-item{
    margin-bottom: 0;
    padding-left: 10px;
}

.nav-filter-button{
    display: flex;
    align-items: center;
}

.modal-header {
    border: none;
}

.modal-header .close {
    color: #FFFFFF;
}

.bold {
    font-weight: bold !important;
}

.bolder {
    font-weight: bolder !important;
}

/* Modal CSS */
.modal-dialog .modal-content .btn-sm {
    height: 45px;
}
.waiting-alert {
  background-color: #FFF7E8;
  border-color: #FFE6BA;
}

.success-alert {
  background-color: #F6FFED;
  border-color: #B7EB8F;
}

.title-box-step-validation {
  height: 44px;
  background: #F5F5F5;
  border-radius: 12px;
}
.subtitle-box-step-validation {
  height: 44px;
  background: #fafafa;
  border-radius: 12px;
}
input[type="file"] {
  display: none;
}

.tax-big-text {
  font-weight: 700;
  font-size: 38px;
  line-height: 46px;
}

.result-layout {
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
  gap: 24px;
}

.result-layout h1 {
  font-size: 24px;
}

.result-layout h2 {
  font-size: 20px;
}

.result-layout .item {
  font-size: 16px;
}

.file-loading {
  display: flex;
  grid-gap: 12px;
  gap: 12px;
  flex-direction: column;
  width: 100%;
  height: 500px;
  justify-content: center;
  align-items: center;
}

.download-btn {
  width: 272px;
  border: 1px solid #1A5ECC;
  border-radius: 12px;
}

.rut-imput:invalid{
  border-color:red;
}

.contract-creation-box {
  display: flex;
  flex-direction: column;
  grid-gap: 48px;
  gap: 48px;
  padding: 24px 16px;
  background-color: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

.seller-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  max-width: 786px;
}

.representative-box {
  max-width: 1000px;
  border: 1px solid #BFBFBF;
  border-radius: 8px;
  padding: 16px;
}

.btn-edit {
  color: #155892;
}

.btn-edit:hover {
  color: #1C72BD;
}

.btn-remove {
  color: #E61F37;
}

.btn-remove:hover {
  color: #FD6A7B;
}

.buyer-btn-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.buyer-btn-group .btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 80px;
  width: 239px;
  box-shadow: 0px 3px 24px -4px rgba(0, 0, 0, 0.1);
}

.buyer-btn-group .btn-active {
  border: 2px solid #0B3254;
  border-radius: 12px !important;
}

.white-circle-close span{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  color: #000000;
  border-radius: 50%;
  background-color: #FFFFFF;
}

.modal-remove-representative .fa-exclamation-circle {
  color: #F5650B !important;
  font-size: 21px;
}


.buyer-btn-group .btn .solo-user-icon,
.buyer-btn-group .btn .fa-building{
  color: #FFAC1A !important;
  font-size: 25px;
}

.buyer-btn-group .btn .group-user-icon{
  color: #FFAC1A !important;
  font-size: 20px;
}

.buyer-btn-group .btn .fa-arrow-right {
  color: #FFAC1A !important;
  font-size: 14px;
}


@media (min-width: 768px) {
  .buyer-btn-group {
    flex-direction: row;
  }

  .buyer-btn-group .btn {
    justify-content: center;
  }
}
.hr {
  flex: 1 1;
  background: #c7c7c7;
  content: "";
  height: 1px;
}

.hr-container {
  display: flex;
  align-items: center;
  font-family: sans-serif;
  width: 100%;
  margin: 15px auto;
  color: #444;
}
.hr-text {
  padding-right: 15px;
  font-weight: bolder;
}

.link-block{
  display: block;
  text-decoration: none !important;
  color: #1A5ECC;
  font-weight: bold;
  padding: 8px;
  font-size: medium;
  cursor: pointer;
}

.pointer {
  cursor: pointer !important;
}

.custom-file-label:after{
  content: "Subir" !important;
  background-color: #1A5ECC;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.h-auto{
  height: auto;
}

.alert-bottom{
  position: fixed;
  bottom: 5px;
  left:2%;
  width: 96%;
}

.custom-height {
  height: 41px !important;
}

.alert-warning-af {
  background: #FFF7E8;
  border: 1px solid #FFE6BA;
  border-radius: 10px;
}

.modal-header .close-icon .fa-times-circle {
  font-size: 18px;
}
/* ContractPreview CSS */

.preview-box {
  padding: 24px 16px;
  grid-gap: 24px;
  gap: 24px;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

.sidebar-actions {
  display: flex;
  flex-direction: column;
  min-width: 310px;
  grid-gap: 24px;
  gap: 24px;
}

.sidebar-actions .btn-pay {
  border-radius: 5px;
  width: 90%;
}

.sign-box {
  background: #F5F5F5;
  border-radius: 8px;
}

.sidebar-tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  height: 47px;
  width: 100%;
}

.sidebar-tabs button {
  padding: 16px;
  height: 47px;
  width: 50%;
  font-size: 13px;
  font-weight: 700;
  color: #8C8C8C;
  background: #FFFFFF;
  border-width: 0px 0px 1px 0px;
  border-style: solid;
  border-color: #C5C5C5;
}

.sidebar-tabs .active {
  color: #155892;
  border-width: 1px 1px 0px 1px;
  border-radius: 2px 2px 0px 0px;
}

.documents-pannel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 18px 16px;
  grid-gap: 16px;
  gap: 16px;

  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: #C5C5C5;
  border-radius: 0px 0px 2px 2px;
}

.documents-pannel div button {
  text-decoration: none !important;
}

.documents-pannel div button:hover {
  color: #2075BE !important;
}

.modal-contract-ok {
  max-width:641px;
}

.modal-contract-edit,
.modal-contract-remove {
  max-width: 893px;
}

.alert-outline-success, .alert-outline-danger {
  background: #F6FFED;
  border-radius: 10px;
}

.alert-outline-success {
  border: 1px solid #B7EB8F;
}

.alert-outline-danger {
  border: 1px solid #EF9F9F;
}

.modal-contract-edit .fa-exclamation-circle,
.modal-contract-remove .fa-exclamation-circle {
  color: #F5650B !important;
  font-size: 21px;
}

.delete-sign-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  padding: 0px;
  grid-gap: 8px;
  gap: 8px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
  color: #E61F37;
}

.thin-border {
  border: 1px solid #D9D9D9;
  border-radius: 8px;
}

.modal-sign {
  max-width: 800px;
}

.qr-width {
  width: 15vw !important;
  height: auto;
  min-height: 175px;
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
   }
}

.qr-loading{
  width: auto;
  height: 268px;
}

@media print {
  #qr-code {
    width: 500px;
    height: auto;
  }
}

.waiting-alert {
  background-color: #FFF7E8;
  border-color: #FFE6BA;
  text-align: start;
}

.payment-resume-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  grid-gap: 16px;
  gap: 16px;
  border: 1px solid #BFBFBF;
  border-radius: 8px;
  width: 466px;
  height: 188px;

}

.repertory-modal, .save-form-modal {
  max-width:641px;
}

.repertory-modal .modal-content .modal-header {
  background-color: #0B3254;
  color: #FFFFFF;
}

.repertory-modal .modal-content .modal-body .form-group label {
  color: #0B3254;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  margin-bottom: 20px;
}

.medium-gap {
  grid-row-gap: 2rem;
  row-gap: 2rem;
}

.h1-title {
  font-size: 1.7rem;
  font-weight:900;
}

.repertory {
  font-size: 1.6rem;
}

.bolder {
  font-weight: bolder !important;
}
