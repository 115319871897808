.btn {
    font-size: medium;
}
.btn > i {
    font-weight: bolder ;
    margin-right: .5em;
}
.font-xl{
    font-size: x-large;
}

.font-sm{
    font-size: 1.1em;
}

.rt-thead .rt-th {
    color: #155892 !important;
}

.btn-sm {
    text-decoration: none;
}

.tabs-container .tab-pane{
    padding: 20px 0 0 0;
}

.nav-tabs .nav-item{
    margin-bottom: 0;
    padding-left: 10px;
}

.nav-filter-button{
    display: flex;
    align-items: center;
}

.modal-header {
    border: none;
}

.modal-header .close {
    color: #FFFFFF;
}

.bold {
    font-weight: bold !important;
}

.bolder {
    font-weight: bolder !important;
}

/* Modal CSS */
.modal-dialog .modal-content .btn-sm {
    height: 45px;
}