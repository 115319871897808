.waiting-alert {
  background-color: #FFF7E8;
  border-color: #FFE6BA;
}

.success-alert {
  background-color: #F6FFED;
  border-color: #B7EB8F;
}

.title-box-step-validation {
  height: 44px;
  background: #F5F5F5;
  border-radius: 12px;
}
.subtitle-box-step-validation {
  height: 44px;
  background: #fafafa;
  border-radius: 12px;
}
input[type="file"] {
  display: none;
}

.tax-big-text {
  font-weight: 700;
  font-size: 38px;
  line-height: 46px;
}

.result-layout {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.result-layout h1 {
  font-size: 24px;
}

.result-layout h2 {
  font-size: 20px;
}

.result-layout .item {
  font-size: 16px;
}

.file-loading {
  display: flex;
  gap: 12px;
  flex-direction: column;
  width: 100%;
  height: 500px;
  justify-content: center;
  align-items: center;
}

.download-btn {
  width: 272px;
  border: 1px solid #1A5ECC;
  border-radius: 12px;
}

.rut-imput:invalid{
  border-color:red;
}

.contract-creation-box {
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding: 24px 16px;
  background-color: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

.seller-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  max-width: 786px;
}

.representative-box {
  max-width: 1000px;
  border: 1px solid #BFBFBF;
  border-radius: 8px;
  padding: 16px;
}

.btn-edit {
  color: #155892;
}

.btn-edit:hover {
  color: #1C72BD;
}

.btn-remove {
  color: #E61F37;
}

.btn-remove:hover {
  color: #FD6A7B;
}

.buyer-btn-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.buyer-btn-group .btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 80px;
  width: 239px;
  box-shadow: 0px 3px 24px -4px rgba(0, 0, 0, 0.1);
}

.buyer-btn-group .btn-active {
  border: 2px solid #0B3254;
  border-radius: 12px !important;
}

.white-circle-close span{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  color: #000000;
  border-radius: 50%;
  background-color: #FFFFFF;
}

.modal-remove-representative .fa-exclamation-circle {
  color: #F5650B !important;
  font-size: 21px;
}


.buyer-btn-group .btn .solo-user-icon,
.buyer-btn-group .btn .fa-building{
  color: #FFAC1A !important;
  font-size: 25px;
}

.buyer-btn-group .btn .group-user-icon{
  color: #FFAC1A !important;
  font-size: 20px;
}

.buyer-btn-group .btn .fa-arrow-right {
  color: #FFAC1A !important;
  font-size: 14px;
}


@media (min-width: 768px) {
  .buyer-btn-group {
    flex-direction: row;
  }

  .buyer-btn-group .btn {
    justify-content: center;
  }
}
.hr {
  flex: 1;
  background: #c7c7c7;
  content: "";
  height: 1px;
}

.hr-container {
  display: flex;
  align-items: center;
  font-family: sans-serif;
  width: 100%;
  margin: 15px auto;
  color: #444;
}
.hr-text {
  padding-right: 15px;
  font-weight: bolder;
}

.link-block{
  display: block;
  text-decoration: none !important;
  color: #1A5ECC;
  font-weight: bold;
  padding: 8px;
  font-size: medium;
  cursor: pointer;
}

.pointer {
  cursor: pointer !important;
}

.custom-file-label:after{
  content: "Subir" !important;
  background-color: #1A5ECC;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.h-auto{
  height: auto;
}

.alert-bottom{
  position: fixed;
  bottom: 5px;
  left:2%;
  width: 96%;
}

.custom-height {
  height: 41px !important;
}

.alert-warning-af {
  background: #FFF7E8;
  border: 1px solid #FFE6BA;
  border-radius: 10px;
}

.modal-header .close-icon .fa-times-circle {
  font-size: 18px;
}